<template>
    <div style="background:#FFFFFF;padding:20px 20px">
        <div>
            <el-page-header @back="goBack" content="详情页面" />
        </div>
        <el-form ref="bankForm" :model="bankForm" :rules="rules"  label-width="80px" style="width: 800px;height: 100%;padding: 10px 0 0 0" >
            <h3 style="text-align: left">
                {{title}}
            </h3>
            <el-form-item label="问题" prop="question">
                <el-input type="textarea" v-model="bankForm.question" placeholder="请输入问题" controls-position="right" maxlength="240"  autocomplete="off"  />
            </el-form-item>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="分类" prop="qid" >
                        <el-select
                                v-model="bankForm.qid"
                                placeholder="请选择分类"
                                clearable
                                size="small"
                                style="width: 100%"
                        >
                            <el-option
                                    v-for="dict in questionTypes"
                                    :key="dict.id"
                                    :label="dict.title"
                                    :value="dict.id"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="状态" label-width="80px" prop="status" autocomplete="off">
                        <el-radio-group v-model="bankForm.status" >
                            <el-radio :label="'1'">显示</el-radio>
                            <el-radio :label="'0'">隐藏</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="分值" prop="figure" >
                        <el-input-number v-model="bankForm.figure"
                                         style="width: 100%"
                                         controls-position="right" :min="0" :max="10"  autocomplete="off"  />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="显示排序" prop="displayorder">
                        <el-input-number v-model="bankForm.displayorder"
                                         style="width: 100%"
                                         controls-position="right" :min="0"  autocomplete="off"  />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="问题类型" label-width="80px" prop="options" autocomplete="off">
                <el-radio-group v-model="bankForm.options" v-if="bankId === undefined">
                    <el-radio :label="1">判断</el-radio>
                    <el-radio :label="2">单选</el-radio>
                    <el-radio :label="3">多选</el-radio>
                    <el-radio :label="4">填写</el-radio>
                </el-radio-group>
                <div v-for="dict in bankType" v-else>
                    <div v-if="dict.id === bankForm.options">
                        <el-input :placeholder="dict.value" :disabled="true" />
                        <el-input :value="dict.id" v-model="bankForm.options" v-show="false" />
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="答案"  v-show="bankForm.options === 1" label-width="80px" prop="status" autocomplete="off">
                <el-radio-group v-model="bankForm.numRight">
                    <el-radio :label="1">对</el-radio>
                    <el-radio :label="2">错</el-radio>
                </el-radio-group>
            </el-form-item>
            <div v-show="bankForm.options === 2" style="text-align: center">
                <span style="color: #999999;">输入框后面字母按钮选择正确答案</span>
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="选项A"  prop="optionA">
                            <el-input v-model="bankForm.optionA" controls-position="right" maxlength="240"  autocomplete="off"  />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-radio-group v-model="bankForm.optiontrue" class="radios">
                            <el-radio :label="'A'">A</el-radio>
                        </el-radio-group>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="选项B"  prop="optionB">
                            <el-input v-model="bankForm.optionB" controls-position="right" maxlength="240"  autocomplete="off"  />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-radio-group v-model="bankForm.optiontrue" class="radios">
                            <el-radio :label="'B'">B</el-radio>
                        </el-radio-group>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="选项C"  prop="optionC">
                            <el-input v-model="bankForm.optionC" controls-position="right" maxlength="240"  autocomplete="off"  />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-radio-group v-model="bankForm.optiontrue" class="radios">
                            <el-radio :label="'C'">C</el-radio>
                        </el-radio-group>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="选项D" prop="optionD">
                            <el-input v-model="bankForm.optionD" controls-position="right" maxlength="240"  autocomplete="off"  />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-radio-group v-model="bankForm.optiontrue" class="radios">
                            <el-radio :label="'D'">D</el-radio>
                        </el-radio-group>
                    </el-col>
                </el-row>
            </div>
            <div v-show="bankForm.options === 3" style="text-align: center">
                <span style="color: #999999;">输入框后面字母按钮选择正确答案</span>
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="选项A"  prop="optionA">
                            <el-input v-model="bankForm.optionA" controls-position="right" maxlength="240"  autocomplete="off"  />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-checkbox-group v-model="bankForm.optiontrue" class="radios"   @change="handleCheckedCitiesChange">
                            <el-checkbox :label="'A'">A</el-checkbox>
                        </el-checkbox-group>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="选项B"  prop="optionB">
                            <el-input v-model="bankForm.optionB" controls-position="right" maxlength="240"  autocomplete="off"  />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-checkbox-group v-model="bankForm.optiontrue" class="radios"   @change="handleCheckedCitiesChange">
                            <el-checkbox :label="'B'">B</el-checkbox>
                        </el-checkbox-group>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="选项C"  prop="optionC">
                            <el-input v-model="bankForm.optionC" controls-position="right" maxlength="240"  autocomplete="off"  />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-checkbox-group v-model="bankForm.optiontrue" class="radios"   @change="handleCheckedCitiesChange">
                            <el-checkbox :label="'C'">C</el-checkbox>
                        </el-checkbox-group>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="选项D" prop="optionD">
                            <el-input v-model="bankForm.optionD" controls-position="right" maxlength="240"  autocomplete="off"  />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-checkbox-group v-model="bankForm.optiontrue" class="radios"   @change="handleCheckedCitiesChange">
                            <el-checkbox :label="'D'">D</el-checkbox>
                        </el-checkbox-group>
                    </el-col>
                </el-row>
            </div>
            <el-form-item label="答案" v-show="bankForm.options === 4" prop="optiontrue">
                <el-input type="textarea" v-model="bankForm.optiontrue" placeholder="请输入答案" maxlength="50" autocomplete="off" :rows="3"></el-input>
                <span style="color: #999999;">任意关键词“|”请为分割。填写任意词则为正确 如：唯一|独一|唯有</span>
            </el-form-item>
            <el-form-item label="描述" prop="description">
                <el-input type="textarea" v-model="bankForm.description" placeholder="请输入描述" maxlength="200" autocomplete="off" :rows="5"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm">确 定</el-button>
        </div>
    </div>
</template>

<script>
    import {queryQuestionType,queryQuestionBankByKey,addQuestionBank,updateQuestionBank} from '@/api/exam/questionBank'
    export default {
        name: "addbank",
        data() {
            return{
                ids: [],
                title: '新增题库内容',
                // 分类
                questionTypes: [],
                bankId: undefined,
                bankType: [{value: '判断', id: 1},{value: '单选', id: 2},{value: '多选', id: 3},{value: '填写', id: 4}],
                bankStatus: [{value: '显示', id: '1'},{value: '隐藏', id: '0'}],
                checkedCities: [],
                bankForm: {
                    id: undefined,
                    qid: undefined,
                    figure: undefined,
                    question: undefined,
                    options: 2,
                    optionA: undefined,
                    optionB: undefined,
                    optionC: undefined,
                    optionD: undefined,
                    optiontrue: [],
                    answer: undefined,
                    numRight: 0,
                    displayorder: 0,
                    status: '1',
                },
                rules: {
                    question:[
                        {required: true, message: "问题不能为空", trigger: "blur"}
                    ],
                    qid: [
                        {required: true, message: "分类不能为空", trigger: "blur"}
                    ],
                    options: [
                        {required: true, message: "问题类型不能为空", trigger: "blur"}
                    ]
                }
            }
        },
        created(){
            this.getQuestionType();
            this.getBankId();
        },
        watch: {
            options(){
                this.bankForm.optiontrue = [];
            }
        },
        methods:{
            getBankId(){
                this.bankId = this.$route.query.bankId;
                const questionBankById = this.bankId;
                if (this.bankId !== undefined){
                    this.title = '修改题库内容';
                    queryQuestionBankByKey({questionBankById}).then(res => {
                        this.bankForm = res.data;
                        if (this.bankForm.options === 3){
                            this.bankForm.optiontrue = res.data.optiontrue.split(",");
                        }
                    })
                }
            },
            handleCheckedCitiesChange(val){
                this.bankForm.optiontrue = [];
                const option = [];
                for (let item of val){
                    option.push(item);
                }
                this.bankForm.optiontrue = option;
            },
            getQuestionType(){
                queryQuestionType().then(res => {
                    this.questionTypes = res.data
                })
            },
            goBack(){
                this.$router.go(-1)
            },
            submitForm(){
                this.$refs['bankForm'].validate(valid => {
                    if (valid){
                        if (this.bankForm.options === 1 && this.bankForm.numRight === 0){
                            this.$message({ message: '对/错至少要选一个', type: 'warning' });
                            return;
                        }
                        if (this.bankForm.options === 2 && this.bankForm.optiontrue.length < 1 ){
                            this.$message({ message: '答案不能为空', type: 'warning' });
                            return;
                        }
                        if (this.bankForm.options === 3 && this.bankForm.optiontrue.length <= 1){
                            this.$message({ message: '多选至少需要两个', type: 'warning' });
                            return;
                        }
                        if (this.bankForm.options === 4 && this.bankForm.optiontrue === undefined){
                            this.$message({ message: '答案不能为空', type: 'warning' });
                            return;
                        }
                        // console.log(this.bankForm.options);
                        if (this.bankForm.options === 3){
                            this.bankForm.optiontrue.sort();
                            this.bankForm.optiontrue = this.bankForm.optiontrue.join(',');
                            // console.log(this.bankForm.optiontrue);
                        }
                        const form = this.bankForm;
                        if (form.id === undefined){
                            addQuestionBank(form).then(res => {
                                this.$message({
                                    message: "添加成功",
                                    type: 'success'
                                });
                                this.$router.push({ path: '/Home/questionBank' });
                            })
                        }else{
                            updateQuestionBank(form).then(res => {
                                this.$message({
                                    message: "修改成功",
                                    type: 'success'
                                });
                                this.$router.push({ path: '/Home/questionBank' });
                            })
                        }
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .radios {
        margin: 12px 0 0 15px;
    }
</style>